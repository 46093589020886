import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Col } from 'react-bootstrap'

const MDXLayout = ({ children }) => {
	const images = useStaticQuery(
		graphql`
			query {
				allGraphCmsAsset(filter: {fileName: {regex: "/(jpg)|(jpeg)|(png)|(JPG)/"}}) {
					nodes {
						localFile {
							childImageSharp {
								gatsbyImageData(layout: CONSTRAINED, height: 500, quality: 100),
							}
						}
						fileName
					}
				}
			}
		`
	)
	const imagesObj = images.allGraphCmsAsset.nodes.reduce((map, entry) => {
		map[entry.fileName] = entry.localFile.childImageSharp.gatsbyImageData
		return map
	}, {})
	const LocalImg = (props) => {
		return <Col xs lg={{ span: 6, offset: 3 }}><GatsbyImage alt="images" image={imagesObj[props.title]} /></Col>
	}

	return (
		<MDXProvider
		components={{
			img: LocalImg,
		}}
		>
		{children}
		</MDXProvider>
	)
}

export default MDXLayout
