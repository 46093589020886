import React from "react"

import { Col } from "react-bootstrap"

const Documents = ({ documents }) => {
	if (documents.length === 0) {
		return null
	}
	const documentEntries = documents.map(entry => {
		return <li key={entry.fileName}><a href={entry.url}>{entry.fileName}</a></li>
	})
	return (
		<Col xs lg="4" className="documents mx-auto rounded">
			<h4>Documents</h4>
			<ul>
				{documentEntries}
			</ul>
		</Col>
	)
}

export default Documents
