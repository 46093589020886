import React from "react"

import { Container, Row, Col } from "react-bootstrap" 
import { Link } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import { MDXRenderer } from "gatsby-plugin-mdx"
import MDXLayout from "../../components/mdxlayout"

import { GatsbyImage } from "gatsby-plugin-image"

import Documents from "../../components/documents"
import publicationDateRendererHOC from "../../components/publication-date-rendererHOC"

import "moment/locale/fr"

const Actualite = ({ displayData, location, data, publicationDate }) => {
	const {
		imageHeader,
		titre,
		document,
		contenu,
	} = displayData;

	const imageHeaderShow = imageHeader ? <GatsbyImage alt="actualité" className="rounded img-center" image={imageHeader.localFile.childImageSharp.gatsbyImageData} /> : null

	return (
		<Layout location={location}>
			<SEO title={titre} />
			<Container>
				<Row className="text-center" style={{ marginTop: '20px' }}>
					<Link to="/actualites/actualites" className="btn btn-primary btn-white btn-sm">Vers toutes les actualités</Link>
				</Row>
				<Row className="justify-content-center text-center mb-5">
					<Col xs={12}>
						<h2>{titre}</h2>
						<p className="publication-date">{publicationDate}</p>
					</Col>
					<Col xs={12} lg={6}>
						{imageHeaderShow}
					</Col>
				</Row>
				<Row className="text-justify mb-5">
					<Col>
						<MDXLayout><MDXRenderer>{contenu.markdownNode.childMdx.body}</MDXRenderer></MDXLayout>
					</Col>
				</Row>
				<Row>
					<Documents documents={document} />
				</Row>
			</Container>
		</Layout>
	)
}

export default publicationDateRendererHOC(Actualite, 'data.graphCmsActualite')

export const ActualiteQuery = graphql`
query ActualiteQuery($id: String) {
	graphCmsActualite(id: { eq: $id}) {
	imageHeader {
		localFile {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, height: 400, quality: 100),
			}
		}
	}
	contenu {
		markdownNode {
			childMdx {
				body
			}
		}
	}

		...ActualiteData
	}
}
`
